import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
import ChecklistResourcesSection from "../../../components/ChecklistResourcesSection/ChecklistResourcesSection.jsx";
import { checklists } from "../../../layout/Constant.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ChecklistResourcesSection figmaUrl={checklists.componentAlert.figmaUrl.ios} codeUrl={checklists.componentAlert.codeUrl.ios} checklists={checklists.componentAlert.checklists} mdxType="ChecklistResourcesSection" />
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`To use the Legion iOS UIKit theme, you need to import one of the available themes.`}</p>
    <p>{`Currently, the following themes are supported: ThemeAGR, ThemeEazy, ThemeIHS, ThemeLGN, and ThemeMyTEnS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import ThemeLGN
`}</code></pre>
    <div className="divi" />
    <h2>{`Variant`}</h2>
    <p>{`Legion Have 4 Variants of Alert:`}</p>
    <h3>{`Error`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/ios-uikit-alert-error.png",
      "alt": "LGNAlertUIKit Variant Error",
      "width": 300
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let alertView = LGNAlertUIKit(
  variant: .error,
  title: "Success title",
  description: "Interactively monetize corporate alignments and fully tested niche markets.",
  actionButtonTitle: "Call to action",
  onTapAction: {
    print("On tap action")
  }
)
`}</code></pre>
    <h3>{`Success`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/ios-uikit-alert-success.png",
      "alt": "LGNAlertUIKit Variant Success",
      "width": 300
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let alertView = LGNAlertUIKit(
  variant: .success,
  title: "Success title",
  description: "Interactively monetize corporate alignments and fully tested niche markets.",
  actionButtonTitle: "Call to action",
  onTapAction: {
    print("On tap action")
  }
)
`}</code></pre>
    <h3>{`Warning`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/ios-uikit-alert-warning.png",
      "alt": "LGNAlertUIKit Variant Warning",
      "width": 300
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let alertView = LGNAlertUIKit(
  variant: .warning,
  title: "Success title",
  description: "Interactively monetize corporate alignments and fully tested niche markets.",
  actionButtonTitle: "Call to action",
  onTapAction: {
    print("On tap action")
  }
)
`}</code></pre>
    <h3>{`Info`}</h3>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/alert/ios-uikit-alert-info.png",
      "alt": "LGNAlertUIKit Variant Info",
      "width": 300
    }}></img>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let alertView = LGNAlertUIKit(
  variant: .info,
  title: "Success title",
  description: "Interactively monetize corporate alignments and fully tested niche markets.",
  actionButtonTitle: "Call to action",
  onTapAction: {
    print("On tap action")
  }
)
`}</code></pre>
    <div className="divi" />
    <h2>{`Properties`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Properties`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Default Value`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionButtonColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the call-to-action button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIColor(hex: “0BA5EC”)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionButtonFont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The font of the call-to-action button text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIFont.systemFont(ofSize: 14, weight: .semibold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionButtonText`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The label for the call-to-action button`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`backgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The background color of the alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIColor(hex: “F5FBFF”)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`borderColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The border color of the alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIColor(hex: “0BA5EC”)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`borderWidth`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The border width of the alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`1.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cornerRadius`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The corner radius of the alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`4.0`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`description`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text to display in the description view`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`descriptionColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the description text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIColor(hex: “667085”)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`descriptionFont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The font of the description text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIFont.systemFont(ofSize: 12, weight: .regular)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIcon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The image to display on the left side of the alert`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIconBackgroundColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The background color for the left icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIColor(hex: “0BA5EC”)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`leftIconTintColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The tint color for the left icon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.white`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The text to display in the title view`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleColor`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The color of the title text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIColor(hex: “1D2939”)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`titleFont`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The font of the title text`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`UIFont.systemFont(ofSize: 16, weight: .bold)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onDismissed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closure that gets called when the alert is dismissed`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`onTapAction`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Closure that gets called when the CTA button is tapped`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`nil`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`variant`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The type of alert (error, success, warning, info)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`.info`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`withDismissButton`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Determines whether the close button is shown`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`true`}</inlineCode></td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      